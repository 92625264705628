import { Center, Paper, Text } from "@mantine/core";
import classes from "../styles/PlaybookCard.module.css";
import { IconClipboardPlus } from "@tabler/icons-react";

interface NewPlaybookCardProps {
  onClick: () => void;
}

export default function NewPlaybookCard({ onClick }: NewPlaybookCardProps) {
  return (
    <Paper className={classes["paper"]} data-new onClick={onClick}>
      <Center className={classes["new-playbook-center"]}>
        <IconClipboardPlus width={30} height={34} />
        <Text className={classes["title-text"]}>Create a new Playbook</Text>
      </Center>
    </Paper>
  );
}
