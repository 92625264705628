import { useApiPrivateRequest } from "../../../shared/hooks/useApi";

export const useDeletePlaybook = () => {
  const {
    data: isDeleted,
    call,
    isLoading: isDeleting,
    isFailed: isDeleteFailed,
  } = useApiPrivateRequest<boolean>((_) => true);

  const deletePlaybook = async (id: string) => {
    await call(`/playbook/${id}`, "DELETE");
  };

  return { isDeleted, deletePlaybook, isDeleting, isDeleteFailed };
};
