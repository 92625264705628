import { rem, Button } from "@mantine/core";
import { useCopyDefaultPlaybook } from "../api/useCopyDefaultPlaybook";
import { useEffect } from "react";
import {
  CustomEvent,
  useEventTrigger,
} from "../../../shared/hooks/useEventManagement";
import { PlaybookShort } from "../model/Playbook";
import { useMediaQuery } from "@mantine/hooks";
import { isLargeScreenMediaQuery } from "../../../shared/utils/ResponsiveStyles";

interface ManagementButtonsProps {
  playbookId: string;
  setActivePlaybook: (playbook: PlaybookShort | undefined) => void;
  setIsDefault: (isDefault: boolean) => void;
}

export default function CopyButton({
  playbookId,
  setActivePlaybook,
  setIsDefault,
}: ManagementButtonsProps) {
  const { copiedPlaybook, copyDefaultPlaybook } = useCopyDefaultPlaybook();
  const dispatchEvent = useEventTrigger();

  const handleCopy = () => {
    if (window.confirm("Are you sure you want to copy this playbook?")) {
      copyDefaultPlaybook(playbookId);
    }
  };

  useEffect(() => {
    if (!copiedPlaybook) return;
    dispatchEvent(CustomEvent.RefreshPlaybooks);
    setIsDefault(false);
    setActivePlaybook(copiedPlaybook);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [copiedPlaybook]);

  const isLargeScreen = useMediaQuery(isLargeScreenMediaQuery);

  return (
    <Button
      variant="secondary"
      size={isLargeScreen ? "sm" : "xs"}
      radius="xs"
      w={{ base: rem(110), xl: rem(145) }}
      onClick={handleCopy}
    >
      Copy Playbook
    </Button>
  );
}
