import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { Playbook } from "../model/Playbook";

export const useGetPlaybook = () => {
  const {
    data: playbook,
    call,
    isLoading,
    isFailed,
    setData: setPlaybook,
  } = useApiPrivateRequest<Playbook>((response) => new Playbook(response));

  const getPlaybook = async (id: string) => {
    await call(`/playbook/${id}`, "GET");
  };

  return { playbook, getPlaybook, isLoading, isFailed, setPlaybook };
};
