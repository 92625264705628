import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { PlaybookShort } from "../model/Playbook";

export const useGetDefaultPlaybooks = () => {
  const {
    data: defaultPlaybooks,
    call,
    isLoading,
    isFailed,
    setData: setPlaybooks,
  } = useApiPrivateRequest<PlaybookShort[]>((response) =>
    response.map((playbook: any) => new PlaybookShort(playbook)),
  );

  const getDefaultPlaybooks = async () => {
    await call(`/playbook/default`, "GET");
  };

  return {
    defaultPlaybooks,
    getDefaultPlaybooks,
    isLoading,
    isFailed,
    setPlaybooks,
  };
};
