import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { ChatShort } from "../../../shared/models/Chat";
import {
  EventName,
  EventParameter,
  EventTitle,
  sendEvent,
} from "../../../shared/utils/UsageAnalytics";

export const useCreateCompanyDocsV2Chat = () => {
  const { data: chat, call } = useApiPrivateRequest<ChatShort>(
    (response) => new ChatShort(response),
  );
  const createChat = async (
    input: string,
    companyIds?: number[],
    filingIds?: string[],
    transcriptIds?: number[],
    files?: File[],
    useNews?: boolean,
  ) => {
    const formatData = new FormData();
    formatData.append("Input", input.trim());
    if (companyIds && companyIds.length > 0) {
      for (let i = 0; i < companyIds.length; i++) {
        formatData.append(`CompanyIds`, companyIds[i].toString());
      }
    }
    if (filingIds && filingIds.length > 0) {
      for (let i = 0; i < filingIds.length; i++) {
        formatData.append(`FilingIds`, filingIds[i]);
      }
    }
    if (transcriptIds && transcriptIds.length > 0) {
      for (let i = 0; i < transcriptIds.length; i++) {
        formatData.append(`TranscriptIds`, transcriptIds[i].toString());
      }
    }
    if (files && files.length > 0) {
      files.forEach((file) => formatData.append("Files", file));
    }
    if (useNews) {
      formatData.append("UseNews", useNews.toString());
    }
    await call("/chat/companyDocsV2", "POST", undefined, formatData);

    sendEvent(EventName.ChatCreated, {
      [EventParameter.ChatMode]: EventTitle.ChatModeCompanyDocsV2,
    });
  };

  return { chat, createChat };
};
