import {
  ActionIcon,
  Center,
  Group,
  Loader,
  rem,
  ScrollArea,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import RunPlaybook from "./components/RunPlaybook";
import { PlaybookShort, PlaybookStepShort } from "./model/Playbook";
import { useEffect, useMemo, useState } from "react";
import { useGetPlaybook } from "./api/useGetPlaybook";
import { IconX } from "@tabler/icons-react";
import { useListState, useMediaQuery } from "@mantine/hooks";
import PlaybookStepsManagement from "./components/PlaybookStepsManagement";
import PlaybookStepView from "./components/PlaybookStepView";
import { useUpdatePlaybook } from "./api/useUpdatePlaybook";
import {
  CustomEvent,
  useEventTrigger,
} from "../../shared/hooks/useEventManagement";
import ManagementButtons from "./components/ManagementButtons";
import { useCreatePlaybook } from "./api/useCreatePlaybook";
import { useGetDefaultPlaybook } from "./api/useGetDefaultPlaybook";
import CopyButton from "./components/CopyButton";
import { isLargeScreenMediaQuery } from "../../shared/utils/ResponsiveStyles";

interface PlaybookManagementProps {
  activeId?: string;
  isDefault: boolean;
  close: () => void;
  setActivePlaybook: (playbook: PlaybookShort | undefined) => void;
  setIsDefault: (isDefault: boolean) => void;
}

export default function PlaybookManagement({
  activeId,
  close,
  isDefault,
  setActivePlaybook,
  setIsDefault,
}: PlaybookManagementProps) {
  const { playbook, getPlaybook, isLoading, setPlaybook } = useGetPlaybook();
  const { defaultPlaybook, getDefaultPlaybook } = useGetDefaultPlaybook();
  const { updatedPlaybook, updatePlaybook, isUpdateFailed } =
    useUpdatePlaybook();
  const { createdPlaybook, createPlaybook, isCreateFailed } =
    useCreatePlaybook();
  const [dragableSteps, { setState, reorder }] =
    useListState<PlaybookStepShort>();
  const [newPlaybookTitle, setNewPlaybookTitle] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const dispatchEvent = useEventTrigger();

  useEffect(() => {
    if (!activeId) {
      setIsEditing(true);
      setPlaybook(null);
      setNewPlaybookTitle("");
      return;
    } else {
      setIsEditing(false);
      if (isDefault) {
        getDefaultPlaybook(activeId);
      } else {
        getPlaybook(activeId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeId]);

  useEffect(() => {
    if (!playbook) return;

    setNewPlaybookTitle(playbook.title);
  }, [playbook, setNewPlaybookTitle]);

  useEffect(() => {
    if (!defaultPlaybook) return;
    setPlaybook(defaultPlaybook);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultPlaybook]);

  const sortedSteps = useMemo(() => {
    if (!playbook) return [];

    return playbook.steps.sort((a, b) => a.order - b.order);
  }, [playbook]);

  useEffect(() => {
    if (!sortedSteps) return;

    setState(sortedSteps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedSteps]);

  /// Playbook validation ///
  const [isInvalid, setIsInvalid] = useState(true);

  useEffect(() => {
    setIsInvalid(
      !dragableSteps.length ||
        dragableSteps.some((step) => !step.title || !step.prompt) ||
        !newPlaybookTitle ||
        (dragableSteps === playbook?.steps &&
          newPlaybookTitle === playbook?.title),
    );
  }, [dragableSteps, newPlaybookTitle, playbook, setIsInvalid]);
  ///
  ///
  const handleCancelChanges = () => {
    if (!playbook) {
      close();
      return;
    }

    setState(sortedSteps);
    setNewPlaybookTitle(playbook.title);
    setIsEditing(false);
  };

  const handleUpdatePlaybook = () => {
    const updatedSteps = dragableSteps.map((step, index) => ({
      ...step,
      order: index,
    }));
    if (!playbook) {
      createPlaybook(newPlaybookTitle, updatedSteps);
    } else {
      updatePlaybook(playbook.id, newPlaybookTitle, updatedSteps);
    }
  };

  useEffect(() => {
    if (!updatedPlaybook || isUpdateFailed) return;

    getPlaybook(updatedPlaybook.id);
    dispatchEvent(CustomEvent.RefreshPlaybooks);
    setIsEditing(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedPlaybook]);

  useEffect(() => {
    if (!createdPlaybook || isCreateFailed) return;

    getPlaybook(createdPlaybook.id);
    dispatchEvent(CustomEvent.RefreshPlaybooks);
    setIsEditing(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdPlaybook]);
  ///
  const turnEditModeOn = () => {
    setIsEditing(true);
  };

  const isLargeScreen = useMediaQuery(isLargeScreenMediaQuery);

  return (
    <Stack
      w={{ base: rem(450), xl: rem(550) }}
      p={20}
      bg="secondary"
      style={{ borderLeft: "1px solid var(--mantine-color-secondary-hover-5)" }}
    >
      <Group justify="flex-end">
        <ActionIcon
          onClick={close}
          variant="simple"
          size={isLargeScreen ? "sm" : "xs"}
        >
          <IconX size={isLargeScreen ? "sm" : "xs"} />
        </ActionIcon>
      </Group>
      <>
        <Group justify="space-between">
          {/* Title rename */}
          {isEditing && (
            <TextInput
              value={newPlaybookTitle}
              placeholder={"Please enter a valid title"}
              error={newPlaybookTitle.replace(/\s/g, "") === ""}
              size="sm"
              radius="xxs"
              c="text-secondary"
              autoFocus
              onChange={(event) =>
                setNewPlaybookTitle(event.currentTarget.value)
              }
            />
          )}
          {!isEditing && playbook && (
            <Text fw={700} fz={{ base: 13, xl: 15 }}>
              {playbook.title}
            </Text>
          )}
          {!isDefault && (
            <ManagementButtons
              isEditing={isEditing}
              isInvalid={isInvalid}
              turnEditModeOn={turnEditModeOn}
              handleCancelChanges={handleCancelChanges}
              handleUpdatePlaybook={handleUpdatePlaybook}
            />
          )}
          {playbook && isDefault && (
            <CopyButton
              playbookId={playbook.id}
              setActivePlaybook={setActivePlaybook}
              setIsDefault={setIsDefault}
            />
          )}
        </Group>
        <Text fw={700} fz={{ base: 13, xl: 15 }}>
          Steps
        </Text>
        <ScrollArea offsetScrollbars>
          {isEditing && (
            <PlaybookStepsManagement
              playbookSteps={sortedSteps}
              draggableSteps={dragableSteps}
              setState={setState}
              reorder={reorder}
            />
          )}
          {!isEditing && (
            <Stack>
              {sortedSteps.length > 0 && (
                <>
                  {sortedSteps.map((step) => (
                    <PlaybookStepView key={step.id} step={step} />
                  ))}
                </>
              )}
              {sortedSteps.length === 0 && (
                <Center>
                  <Text>No steps yet</Text>
                </Center>
              )}
            </Stack>
          )}
        </ScrollArea>
        {!isEditing && playbook && (
          <RunPlaybook playbook={playbook} isDefault={isDefault} />
        )}
      </>
      {isLoading && (
        <Center h="100%" w="100%">
          <Loader />
        </Center>
      )}
    </Stack>
  );
}
