import {
  rem,
  ActionIcon,
  Group,
  Paper,
  Stack,
  Text,
  Menu,
  TextInput,
  Center,
} from "@mantine/core";
import classes from "../styles/PlaybookCard.module.css";
import { IconDotsVertical } from "@tabler/icons-react";
import { useEffect, useRef, useState } from "react";
import {
  CustomEvent,
  useEventTrigger,
} from "../../../shared/hooks/useEventManagement";
import { useDeletePlaybook } from "../api/useDeletePlaybook";
import { PlaybookShort } from "../model/Playbook";
import { useRenamePlaybook } from "../api/useRenamePlaybook";
import { useCopyDefaultPlaybook } from "../api/useCopyDefaultPlaybook";

interface PlaybookCardProps {
  playbook: PlaybookShort;
  onClick: () => void;
  setActivePlaybook: (playbookShort?: PlaybookShort) => void;
  isDefault: boolean;
}

export default function PlaybookCard({
  playbook,
  onClick,
  setActivePlaybook,
  isDefault,
}: PlaybookCardProps) {
  const { isRenamed, renamePlaybook, isRenameFailed } = useRenamePlaybook();
  const { isDeleted, deletePlaybook } = useDeletePlaybook();
  const { copiedPlaybook: isCopied, copyDefaultPlaybook } =
    useCopyDefaultPlaybook();
  const dispatchEvent = useEventTrigger();
  const menuBoxRef = useRef<HTMLDivElement | null>(null);
  const menuDropdownRef = useRef<HTMLDivElement | null>(null);

  const [opened, setOpened] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [value, setValue] = useState(playbook.title);

  const handleEdit = () => {
    setEditMode(true);
    setOpened(true);
  };

  const handleSave = () => {
    if (value.replace(/\s/g, "") === "") return;
    renamePlaybook(playbook.id, value);
  };

  useEffect(() => {
    if (!isRenamed || isRenameFailed) return;
    setTimeout(() => setEditMode(false), 100);
  }, [isRenamed, isRenameFailed]);

  const handleDelete = () => {
    if (window.confirm("Are you sure you want to delete this playbook?")) {
      deletePlaybook(playbook.id);
    }
  };

  useEffect(() => {
    if (!isDeleted) return;
    setActivePlaybook(undefined);
    dispatchEvent(CustomEvent.RefreshPlaybooks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleted]);

  const handleCardClick = (
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
  ) => {
    if (editMode) return;
    const refs = [menuBoxRef.current, menuDropdownRef.current];
    if (refs.some((ref) => ref?.contains(e.target as Node))) {
      return;
    }
    onClick();
  };

  const handleCopy = () => {
    if (window.confirm("Are you sure you want to copy this playbook?"))
      copyDefaultPlaybook(playbook.id);
  };

  useEffect(() => {
    if (!isCopied) return;
    dispatchEvent(CustomEvent.RefreshPlaybooks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCopied]);

  return (
    <Paper className={classes["paper"]} onClick={handleCardClick}>
      <Group className={classes["group"]}>
        <Stack className={classes["stack"]}>
          {editMode && (
            <TextInput
              value={value}
              placeholder="Please enter a template name"
              error={value.replace(/\s/g, "") === ""}
              size="xs"
              radius="xxs"
              c="text-secondary"
              autoFocus
              onChange={(event) => setValue(event.currentTarget.value)}
              onBlur={handleSave}
            />
          )}
          {!editMode && (
            <Text className={classes["title-text"]} truncate>
              {value}
            </Text>
          )}
        </Stack>
        <Center ref={menuBoxRef}>
          <Menu
            opened={opened}
            position="left-start"
            offset={0}
            onChange={setOpened}
          >
            <Menu.Target>
              <ActionIcon size={rem(24)} variant="transparent">
                <IconDotsVertical color={`var(--mantine-color-black)`} />
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown ref={menuDropdownRef}>
              {!isDefault && (
                <Menu.Item
                  className={classes["playbook-menu-item"]}
                  px={8}
                  onClick={handleEdit}
                >
                  Rename
                </Menu.Item>
              )}
              {!isDefault && (
                <Menu.Item
                  className={classes["playbook-menu-item"]}
                  px={8}
                  onClick={handleDelete}
                >
                  Delete
                </Menu.Item>
              )}
              {isDefault && (
                <Menu.Item
                  className={classes["playbook-menu-item"]}
                  px={8}
                  onClick={handleCopy}
                >
                  Copy
                </Menu.Item>
              )}
            </Menu.Dropdown>
          </Menu>
        </Center>
      </Group>
    </Paper>
  );
}
