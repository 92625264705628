import { useEffect } from "react";
import { Box } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import ChatFooter from "../chat-footer/ChatFooter";
import classes from "./styles/NewDocsChat.module.css";
import { useCreateCompanyDocsV2Chat } from "./api/useCreateCompanyDocsV2Chat";

export default function NewDocsChat() {
  const navigate = useNavigate();
  const { chat, createChat } = useCreateCompanyDocsV2Chat();

  useEffect(() => {
    if (!chat) return;
    navigate(`/c/${chat.id}`);
  }, [chat, navigate]);

  return (
    <Box className={classes["root-container"]}>
      <ChatFooter processUserInput={createChat} useNewsDefault={true} />
    </Box>
  );
}
