import { useApiPrivateRequest } from "../../../shared/hooks/useApi";

export const useRenamePlaybook = () => {
  const {
    data: isRenamed,
    call,
    isLoading: isRenaming,
    isFailed: isRenameFailed,
  } = useApiPrivateRequest<boolean>((_) => true);

  const renamePlaybook = async (id: string, title: string) => {
    await call(`/playbook/${id}/rename`, "PUT", undefined, {
      title,
    });
  };

  return {
    isRenamed,
    renamePlaybook,
    isRenaming,
    isRenameFailed,
  };
};
