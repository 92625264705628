import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { Playbook } from "../model/Playbook";

export const useGetDefaultPlaybook = () => {
  const {
    data: defaultPlaybook,
    call,
    isLoading,
    isFailed,
    setData: setDefaultPlaybook,
  } = useApiPrivateRequest<Playbook>((response) => new Playbook(response));

  const getDefaultPlaybook = async (id: string) => {
    await call(`/playbook/default/${id}`, "GET");
  };

  return {
    defaultPlaybook,
    getDefaultPlaybook,
    isLoading,
    isFailed,
    setDefaultPlaybook,
  };
};
