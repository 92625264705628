import { useDisclosure, useLocalStorage, useMediaQuery } from "@mantine/hooks";
import AddDocumentsModal from "../../add-documents-modal/AddDocumentsModal";
import { useEffect, useState } from "react";
import { FileWithPath } from "@mantine/dropzone";
import { CompanyDocumentView } from "../../../shared/models/CompanyDocument";
import { CompanyInfo } from "../../../shared/components/company-info/models/CompanyInfo";
import { AddedDocuments } from "../../add-documents-modal/components/AddedDocuments";
import { Playbook } from "../model/Playbook";
import { Button, Checkbox, Group, Stack, Text } from "@mantine/core";
import { useCreatePlaybookChat } from "../api/useCreatePlaybookChat";
import { CompanyDocumentType } from "../../../shared/enums/CompanyDocumentType";
import { useNavigate } from "react-router-dom";
import { isLargeScreenMediaQuery } from "../../../shared/utils/ResponsiveStyles";
import { LocalStorageKeys } from "../../../shared/enums/LocalStorageKeys";

interface RunPlaybookProps {
  playbook: Playbook;
  isDefault: boolean;
}

export default function RunPlaybook({ playbook, isDefault }: RunPlaybookProps) {
  const navigate = useNavigate();
  const { chat, createChat } = useCreatePlaybookChat();
  const [
    openedAddDocuments,
    { open: openAddDocuments, close: closeAddDocuments },
  ] = useDisclosure(false);
  const [userFiles, setUserFiles] = useState<FileWithPath[]>([]);
  const [companyDocs, setCompanyDocs] = useState<CompanyDocumentView[]>([]);
  const [companies, setCompanies] = useState<CompanyInfo[]>([]);
  const [, setDownload] = useLocalStorage({
    key: LocalStorageKeys.DownloadChatOnFinish,
  });
  const [sendPdf, setSendPdf] = useState(false);
  const [useNews, setUseNews] = useState(true);

  useEffect(() => {
    if (!chat) return;
    if (sendPdf) {
      setDownload(chat!.id);
    }
    navigate(`/c/${chat.id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chat, navigate]);

  const handleStartChat = async (sendPdf: boolean) => {
    await createChat(
      playbook.id,
      isDefault,
      playbook.title,
      false,
      companies.map((company) => company.id),
      companyDocs
        .filter((d) => d.type === CompanyDocumentType.Filing)
        .map((doc) => doc.id),
      companyDocs
        .filter((d) => d.type === CompanyDocumentType.Transcript)
        .map((doc) => parseInt(doc.id)),
      userFiles,
      useNews,
    );
    setSendPdf(sendPdf);
  };

  const contextIsEmpty = () => {
    return (
      companies.length === 0 &&
      companyDocs.length === 0 &&
      userFiles.length === 0
    );
  };
  const isLargeScreen = useMediaQuery(isLargeScreenMediaQuery);

  return (
    <Stack mt="auto">
      <AddDocumentsModal
        opened={openedAddDocuments}
        close={closeAddDocuments}
        userFiles={userFiles}
        setUserFiles={setUserFiles}
        companyDocs={companyDocs}
        setCompanyDocs={setCompanyDocs}
        companies={companies}
        setCompanies={setCompanies}
      />

      <Text fw={700} fz={{ base: 13, xl: 15 }}>
        Context
      </Text>
      <Group>
        <Button
          variant="secondary"
          radius="xs"
          size="sm"
          w="70%"
          h={35}
          onClick={openAddDocuments}
          disabled={!playbook.steps || playbook.steps.length === 0}
        >
          <Text fz={{ base: 14, xl: 16 }}>Add Companies or Documents</Text>
        </Button>
        <Group
          w="25%"
          h={35}
          onClick={() => setUseNews(!useNews)}
          style={{ cursor: "pointer" }}
          gap="xs"
        >
          <Checkbox checked={useNews} radius="xxs" size="sm" />
          <Text fz={{ base: 14, xl: 16 }}>Use news</Text>
        </Group>
      </Group>
      <AddedDocuments
        userFiles={userFiles}
        setUserFiles={setUserFiles}
        companyDocs={companyDocs}
        setCompanyDocs={setCompanyDocs}
        companies={companies}
        setCompanies={setCompanies}
      />
      {!playbook.steps || playbook.steps.length === 0 ? (
        <Text fz={{ base: 11, xl: 13 }}>
          To start a playbook chat, it should have at least one step
        </Text>
      ) : (
        contextIsEmpty() && (
          <Text fz={{ base: 11, xl: 13 }}>
            To start a playbook chat, you should add context
          </Text>
        )
      )}
      <Group grow>
        <Button
          variant="primary"
          radius="xs"
          size={isLargeScreen ? "sm" : "xs"}
          h={{ base: 40, xl: 50 }}
          disabled={contextIsEmpty()}
          onClick={() => handleStartChat(false)}
        >
          <Text fz={{ base: 16, xl: 20 }}>Start Playbook</Text>
        </Button>
        <Button
          variant="primary"
          radius="xs"
          size={isLargeScreen ? "sm" : "xs"}
          h={{ base: 40, xl: 50 }}
          disabled={contextIsEmpty()}
          onClick={() => handleStartChat(true)}
        >
          <Text fz={{ base: 16, xl: 20 }}>Download PDF report</Text>
        </Button>
      </Group>
    </Stack>
  );
}
