import { Divider, Flex, ScrollArea, SimpleGrid, Text } from "@mantine/core";
import { useHasRole } from "../../shared/hooks/useHasRole";
import { UserRole } from "../../shared/enums/UserRole";
import { useEffect, useState } from "react";
import classes from "./styles/HomePageBody.module.css";
import NewDocsChat from "../new-chat/NewDocsChat";
import PlaybookCard from "../playbook/components/PlaybookCard";
import PlaybookManagement from "../playbook/PlaybookManagement";
import NewPlaybookCard from "../playbook/components/NewPlaybookCard";
import { useGetPlaybooks } from "../playbook/api/useGetPlaybooks";
import { PlaybookShort } from "../playbook/model/Playbook";
import {
  CustomEvent,
  useEventListener,
} from "../../shared/hooks/useEventManagement";
import { useDisclosure } from "@mantine/hooks";
import { useGetDefaultPlaybooks } from "../playbook/api/useGetDefaultPlaybooks";
import CenterLoader from "../../shared/components/loader/CenterLoader";

export default function HomePageBody() {
  const chatDisabled = useHasRole(UserRole.ChatDisabled);
  const { playbooks, getPlaybooks, isLoading } = useGetPlaybooks();
  const { defaultPlaybooks, getDefaultPlaybooks } = useGetDefaultPlaybooks();
  const [activePlaybook, setActivePlaybook] = useState<PlaybookShort>();
  const [isActiveDefaultPlaybook, setIsActiveDefaultPlaybook] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);

  useEventListener(CustomEvent.RefreshPlaybooks, () => getPlaybooks());

  useEffect(() => {
    getPlaybooks();
    getDefaultPlaybooks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCardClick = (playbookShort?: PlaybookShort) => {
    setActivePlaybook(playbookShort);
    setIsActiveDefaultPlaybook(false);
    open();
  };

  const handleDefaultCardClick = (playbookShort?: PlaybookShort) => {
    setActivePlaybook(playbookShort);
    setIsActiveDefaultPlaybook(true);
    open();
  };

  const handleNewPlaybookClick = () => {
    setActivePlaybook(undefined);
    setIsActiveDefaultPlaybook(false);
    open();
  };

  return (
    <Flex className={classes["root"]}>
      <Flex className={classes["main"]}>
        <Text fw={700} fz={{ base: "xl", xl: "xxl" }} c="primary">
          Your playbooks
        </Text>
        {isLoading && <CenterLoader />}
        {playbooks && (
          <ScrollArea scrollbars="y" h="40%">
            <SimpleGrid
              cols={{ base: 1, sm: 2, md: opened ? 3 : 4 }}
              spacing={{ base: 12, xl: 20 }}
              verticalSpacing={{ base: 12, xl: 20 }}
            >
              <NewPlaybookCard onClick={handleNewPlaybookClick} />
              {playbooks.map((playbook) => (
                <PlaybookCard
                  key={playbook.id}
                  playbook={playbook}
                  onClick={() => handleCardClick(playbook)}
                  setActivePlaybook={setActivePlaybook}
                  isDefault={false}
                />
              ))}
            </SimpleGrid>
          </ScrollArea>
        )}

        <Divider />
        <Text fw={700} fz={{ base: "xl", xl: "xxl" }} c="primary">
          Quantly playbooks
        </Text>
        {defaultPlaybooks && (
          <ScrollArea scrollbars="y" h="60%">
            <SimpleGrid
              cols={{ base: 1, sm: 2, md: opened ? 3 : 4 }}
              spacing={{ base: 12, xl: 20 }}
              verticalSpacing={{ base: 12, xl: 20 }}
            >
              {defaultPlaybooks.map((playbook) => (
                <PlaybookCard
                  key={playbook.id}
                  playbook={playbook}
                  onClick={() => handleDefaultCardClick(playbook)}
                  setActivePlaybook={setActivePlaybook}
                  isDefault={true}
                />
              ))}
            </SimpleGrid>
          </ScrollArea>
        )}
        {!opened && !chatDisabled && <NewDocsChat />}
      </Flex>
      {opened && (
        <PlaybookManagement
          activeId={activePlaybook?.id}
          close={close}
          isDefault={isActiveDefaultPlaybook}
          setActivePlaybook={setActivePlaybook}
          setIsDefault={setIsActiveDefaultPlaybook}
        />
      )}
    </Flex>
  );
}
