import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { Playbook } from "../model/Playbook";

export const useCopyDefaultPlaybook = () => {
  const {
    data: copiedPlaybook,
    call,
    isLoading: isCopying,
    isFailed: isCopyFailed,
  } = useApiPrivateRequest<Playbook>((response) => new Playbook(response));

  const copyDefaultPlaybook = async (id: string) => {
    await call(`/playbook/default/${id}/copy`, "POST", undefined);
  };

  return {
    copiedPlaybook,
    copyDefaultPlaybook,
    isCopying,
    isCopyFailed,
  };
};
