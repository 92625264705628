import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { PlaybookShort } from "../model/Playbook";

export const useGetPlaybooks = () => {
  const {
    data: playbooks,
    call,
    isLoading,
    isFailed,
    setData: setPlaybooks,
  } = useApiPrivateRequest<PlaybookShort[]>((response) =>
    response.map((playbook: any) => new PlaybookShort(playbook)),
  );

  const getPlaybooks = async () => {
    await call(`/playbook`, "GET");
  };

  return { playbooks, getPlaybooks, isLoading, isFailed, setPlaybooks };
};
