import { Group, rem, Button } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { isLargeScreenMediaQuery } from "../../../shared/utils/ResponsiveStyles";

interface ManagementButtonsProps {
  isEditing: boolean;
  isInvalid: boolean;
  turnEditModeOn: () => void;
  handleCancelChanges: () => void;
  handleUpdatePlaybook: () => void;
}

export default function ManagementButtons({
  isEditing,
  isInvalid,
  turnEditModeOn,
  handleCancelChanges,
  handleUpdatePlaybook,
}: ManagementButtonsProps) {
  const isLargeScreen = useMediaQuery(isLargeScreenMediaQuery);
  return (
    <>
      {isEditing ? (
        <Group gap={rem(10)}>
          <Button
            variant="secondary"
            radius="xs"
            size={isLargeScreen ? "sm" : "xs"}
            onClick={handleCancelChanges}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            radius="xs"
            size={isLargeScreen ? "sm" : "xs"}
            w={isLargeScreen ? rem(145) : rem(110)}
            disabled={isInvalid}
            onClick={handleUpdatePlaybook}
          >
            Save Playbook
          </Button>
        </Group>
      ) : (
        <Button
          variant="secondary"
          radius="xs"
          size={isLargeScreen ? "sm" : "xs"}
          w={isLargeScreen ? rem(145) : rem(110)}
          onClick={turnEditModeOn}
        >
          Edit Playbook
        </Button>
      )}
    </>
  );
}
