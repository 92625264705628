import { Stack, Text } from "@mantine/core";
import { PlaybookStep } from "../model/Playbook";
import classes from "../styles/PlaybookStepView.module.css";

interface PlaybookStepProps {
  step: PlaybookStep;
}

export default function PlaybookStepView({ step }: PlaybookStepProps) {
  return (
    <Stack className={classes["root"]}>
      <Text fw={700} fz={{ base: 13, xl: 15 }}>
        {step.title}
      </Text>
      <Text fz={{ base: 12, xl: 14 }}>{step.prompt}</Text>
    </Stack>
  );
}
